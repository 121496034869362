//* IFCA x series products font family : Poppins , sans-serif
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

//* Design System
@import './color.scss';
@import './typography.scss';
@import './spacing.scss';
@import './shadow.scss';
//* Layout
@import '../components/AuthWrapper/auth.scss';
@import '../components/Layout/layout.scss';
//* Component
@import './common.scss';
@import '../components/CopyRight/copyright.scss';
@import '../components/MainHeader/mainheader.scss';
@import '../components/Menu/menu.scss';
@import '../components/SeachFilter/searchfilter.scss';
@import '../components/ListItem/listitem.scss';
@import '../components/ListItem/imageitem.scss';
@import '../components/ServicesOption/servicesoption.scss';
@import '../components/CommonDialog/commondialog.scss';
@import '../components/Card/card.scss';
@import '../components/Footer/footer.scss';
@import '../components/Chip/chip.scss';
@import '../components/Swiper/swiper.scss';
@import '../components/Drawer/drawer.scss';
@import '../components/DatePicker/date.scss';
@import '../components/Accordion/accordion.scss';
@import '../components/others/qrreader.scss';
@import '../components/Button/fab.scss';
@import '../components/Button/iconbutton.scss';
//
@import 'swiper/swiper.scss';
@import 'swiper/components/pagination/pagination.scss';
body {
  font-family: 'Poppins', sans-serif;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: $c-gradient-2;
  color: $c-text;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
