.accordion-wrapper.MuiAccordion-root {
  @include border-box-shadow;
  border-radius: 10px !important;
  color: $c-text;
}
.accordion-wrapper {
  width: 100%;
  margin-bottom: 14px;
  //   .MuiAccordionSummary-content {
  //     margin: 0px;
  //   }
  .MuiAccordionSummary-root {
    // display: flex;
    padding: 0px;
    width: 100%;
    .MuiIconButton-root {
      padding: 0px;
      background: linear-gradient(0deg, #2f5cff 0%, #2994ff 100%);
      color: #fff;
    }
  }
  .MuiAccordionSummary-root.Mui-expanded {
    min-height: 54px;
  }
  .accord-title {
    display: flex;
    padding: 4px 6px 4px 0px;
    width: 100%;

    .more-icon {
      margin-left: 6px;
      padding: 0px 4px;
      border-radius: 6px !important;
      @include border-box-shadow;
      img {
        width: 15px;
      }
    }
  }
  .accord-content {
    img {
      width: 14px;
      height: 14px;
      margin-right: 4px;
    }
    a {
      color: #818cac;
    }
  }
  .MuiAccordionSummary-content.Mui-expanded {
    margin: 0px;
  }
  .MuiAccordionSummary-content {
    margin: 0px;
    padding-left: 12px;
  }
  .MuiIconButton-edgeEnd {
    margin-right: 12px;
  }

  .MuiAccordionDetails-root {
    padding: 12px;
    display: block;
  }
}
