.hotel-swiper.swiper-container {
  @media screen and (max-width: 600px) {
    width: 100%;
    z-index: 100;
    top: 0;
    left: auto;
    right: 0;
    position: fixed !important;
  }
}
.hotel-swiper {
  .swiper-pagination-bullet-active {
    background: white;
  }
  .swiper-slide {
    text-align: center;
    width: 100% !important;
    height: 100% !important;
    .swiper-slide {
      background-position: center;
      background-size: cover;
    }
    img {
      width: 100%;
      object-fit: contain;
      background: #212121;
      height: 350px;
      @media screen and (max-width: 600px) {
        height: 220px !important;
        width: 100%;
        object-fit: cover;
      }
    }
  }
}

.basic-swiper.swiper-container {
  width: 100%;
  z-index: 100;
  @include border-box-shadow;
  display: flex;
}
.basic-swiper {
  .swiper-pagination-bullet-active {
    background: white;
  }
  .swiper-slide {
    text-align: center;
    width: 100% !important;
    height: 350px !important;
    @media screen and (max-width: 600px) {
      height: 195px !important;
    }
    .swiper-slide {
      background-position: center;
      background-size: cover;
    }
    img {
      width: 100%;
      object-fit: contain;
      background: #212121;
      height: 350px;
      @media screen and (max-width: 600px) {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
