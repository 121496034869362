.fab {
  position: fixed !important;
  right: 24px;
  bottom: 45px;
  box-shadow: 5px 5px 10px #cccccc, -5px -5px 10px #f9f9f9;
  background: $c-gradient;
  .MuiFab-label {
    color: #fff;
  }
  &.with-footer {
    bottom: 68px;
  }
  &.with-float-amt {
    bottom: 100px;
  }
}
