.menu-wrapper {
  .MuiMenu-paper {
    margin-top: 18px;
    border-radius: 6px;
    background: $c-gradient-1;
    box-shadow: 2px 2px 4px #cccccc, -2px -2px 4px #f9f9f9;
    -webkit-overflow-scrolling: touch !important;
  }
  .MuiList-padding {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .MuiMenuItem-root {
    padding: 10px 12px;
    font-size: 14px;
    color: $c-text;
    font-weight: 500;
    svg {
      height: 30px;
      width: 30px;
    }
  }
}

.filter-popover {
  .MuiPopover-paper {
    color: $c-text;
    min-width: 280px;
    width: 100% !important;
    max-width: 280px !important;
    border-radius: 6px;
    background: $c-gradient-1;
    box-shadow: 2px 2px 4px #cccccc, -2px -2px 4px #f9f9f9;
  }

  .plus-minus-section {
    .counts {
      padding: 0px 8px;
    }
    @include plus-minus-button;
    .MuiIconButton-root {
      background: $c-gradient;
      color: $c-white;
    }
  }
  .pointer {
    cursor: pointer;
  }
}
