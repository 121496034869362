.chip-sticky {
  @media screen and (max-width: 599px) {
    position: fixed;
    top: 68px;
    left: 0;
    right: 0;
    z-index: 2;
    width: 100%;
    background: $c-gradient-2;
  }
  .chip-container {
    white-space: nowrap;
    overflow: auto;
    padding: 0px 20px;
    // margin-bottom: 12px;
    .MuiChip-root {
      border-radius: 7px;
      // box-shadow: 2px 2px 4px #a7a9b4, -2px -2px 4px #ffffff;
      @include standard-boxshadow;
      margin: 8px;
      background: transparent;
      color: $c-text;
      font-weight: 600;
    }
  }
}
