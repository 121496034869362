@import '../../styles/color.scss';
@import '../../styles/typography.scss';
@import '../../styles/mixins.scss';

//* Auth wrapper
.auth-layout {
  background-size: cover !important;
  background: #212121;
  

    max-height: 1800px;
    height: auto;
    min-height: 1000px;
  display: flex;
  align-items: center;
  // margin-bottom: 20px;
  // Center box
  
  .auth-box {
    max-width: 390px;
    min-width: 280px;
    transform: translateX(-50%);
    position: absolute; /* Add position: absolute; */
  left: 50%; /* Center horizontally */
    margin-top: 80px;
    top: 0;
    width: 80vw;
    padding: 20px 12px;
    border-radius: 10px;
    background: rgba(223, 227, 235, 0.9); // opacity 0,9
    // background-color: $c-bg-auth;
    height: auto;
    box-shadow: 20px 20px 60px #babdd0, -20px -20px 60px #fcffff;
    .app-logo {
      width: 48vw;
      max-width: 125px;
      max-height: 125px;
      display: block;
      margin: 0 auto;
    }
    .auth-title {
      color: $c-text;
      display: block;
      margin: 8px 0px 0px;
      text-align: center;
      font-size: 16px;
      font-weight: bold;
    }
    .auth-subtitle {
      color: $c-text;
      display: block;
      margin: 16px 0px 0px;
      font-size: 14px;
      text-align: center;
      white-space: break-spaces;
      line-height: 18px;
      letter-spacing:1px;
    }

    // .logo-title {
    //   color: #ffb11f;
    //   font-size: 14px;
    //   font-weight: 600;
    //   margin-right: 28px;
    //   margin-top: -2px;
    //   display: block;
    //   text-align: right;
    // }

    .form-wrapper {
      margin-top: 15px;
      .MuiFormControl-root {
        // width: 100%;
        margin-bottom: 16px;
        // .MuiFormLabel-root {
        //   color: rgba(255, 255, 255, 0.54);
        // }
        // .MuiInputBase-root {
        //   color: rgba(255, 255, 255, 0.87);
        // }
        // .MuiInput-underline:before {
        //   border-bottom: 1px solid rgba(255, 255, 255, 0.42);
        // }
        // .MuiIconButton-root {
        //   color: rgba(255, 255, 255, 0.54);
        // }
      }
      .other-auth {
        display: flex;
        align-items: center;
        .MuiCheckbox-root {
          color: #394d86;
        }
        .MuiFormControlLabel-label {
          color: #394d86;
          font-size: 12px;
        }
      }
      .forget-password {
        color: #394d86;
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
        text-align: right;
        display: block;
        cursor: pointer;
        flex: 1;
      }
    }
    .button-box {
      position: absolute;
      margin-top: 45px;
      max-width: 400px;
      min-width: 280px;
      width: 80vw;
      .login-btn {
        @include main-button;
      }
    }
  }
}

