@import '../../styles/color.scss';
@import '../../styles/mixins.scss';
.copyright-layout {
  background: $c-grey-1;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0),
    0 1px 10px 2px rgba(0, 0, 0, 0.05), 0 0px 5px 0 rgba(0, 0, 0, 0.1);
  @include flex-width;
  position: fixed;
  padding: 8px 0px;
  bottom: 0;
  right: 0;
  color: $c-text;
  .left-label {
    flex: 1;
  }
  a {
    text-decoration: none;
  }
  .ifca-logo {
    width: 60px;
    min-width: 60px;
  }
}
