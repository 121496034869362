.content-wrapper {
  padding: 15px 20px;
  margin-bottom: 30px;
  @media screen and (min-width: 600px) {
    // margin-top: 64px;
  }
  &.with-footer {
    margin-bottom: 70px;
  }
  &.with-sub {
    @media screen and (max-width: 599px) {
      margin-top: 48px;
    }
  }
  &.with-img {
    @media screen and (max-width: 600px) {
      margin-top: 152px;
    }
  }
  &.with-fab {
    margin-bottom: 80px;
  }
}
