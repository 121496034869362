.core-image-list {
  .MuiGridListTile-tile {
    @include border-box-shadow;
    height: 178px;
  }
  .MuiGridListTileBar-rootSubtitle {
    height: 60px;
  }
  .MuiGridListTileBar-root {
    background: $c-gradient-1;
    .MuiGridListTileBar-title {
      color: $c-text;
      font-size: 14px;
      font-weight: 800;
      margin-bottom: 4px;
    }
    .MuiGridListTileBar-subtitle {
      color: $c-text;
      font-size: 10px;
      font-weight: 500;
    }
  }
  .MuiGridListTile-imgFullWidth {
    height: 100% !important;
    object-fit: cover;
  }

  .MuiIconButton-root {
    box-shadow: 3px 3px 6px #cccccc, -3px -3px 6px #f9f9f9;

    background: linear-gradient(0deg, #2f5cff 0%, #2994ff 100%);
    color: #fff;
    width: 25px;
    height: 25px;
  }
  .order.MuiGridListTileBar-root {
    display: block;
    padding: 6px 12px;
    .MuiGridListTileBar-title {
      line-height: 18px;
    }
    .MuiGridListTileBar-actionIcon {
      display: flex;
      justify-content: flex-end;
      .plus-minus {
        width: 50%;
        display: flex;
        justify-content: flex-end;
        .count {
          margin: auto;
        }
        @include plus-minus-button;
        .MuiIconButton-root {
          background: $c-gradient;
          color: $c-white;
          box-shadow: 5px 5px 10px #cccccc, -5px -5px 10px #f9f9f9;
        }
      }
    }
    .MuiGridListTileBar-titleWrap {
      margin-left: 0px;
    }
  }
  .order.MuiGridListTile-tile {
    height: 190px;
    .MuiGridListTile-imgFullWidth {
      height: 100% !important;
      object-fit: cover;
    }
  }
}
