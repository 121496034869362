.bills-wrapper {
  display: inline-block;
  .MuiListItem-root {
    padding: 0px;
    // padding-bottom: 8px;
  }
  .MuiListItemIcon-root {
    min-width: 34px;
  }
}
