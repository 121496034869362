@import './mixins.scss';

main {
  width: calc(100% - 300px);
}
html,
body {
  min-height: 100%;
  .p-0 {
    padding: 0px;
  }
  .p-10 {
    padding: 10px;
  }
  .p-20 {
    padding: 20px;
  }
  .p-30 {
    padding: 30px;
  }
  .p-l-0 {
    padding-left: 0px;
  }
  .p-l-6 {
    padding-left: 6px;
  }
  .p-l-10 {
    padding-left: 10px;
  }
  .p-l-20 {
    padding-left: 20px;
  }
  .p-r-0 {
    padding-right: 0px;
  }
  .p-r-6 {
    padding-right: 6px;
  }
  .p-r-10 {
    padding-right: 10px;
  }
  .p-r-20 {
    padding-right: 20px;
  }
  .p-r-30 {
    padding-right: 30px;
  }
  .p-r-40 {
    padding-right: 40px;
  }
  .p-t-0 {
    padding-top: 0px;
  }
  .p-t-10 {
    padding-top: 10px;
  }
  html body .p-t-20 {
    padding-top: 20px;
  }
  .p-t-30 {
    padding-top: 30px;
  }
  .p-b-0 {
    padding-bottom: 0px;
  }
  .p-b-5 {
    padding-bottom: 5px;
  }
  .p-b-10 {
    padding-bottom: 10px;
  }
  .p-b-20 {
    padding-bottom: 20px;
  }
  .p-b-30 {
    padding-bottom: 30px;
  }
  .p-b-40 {
    padding-bottom: 40px;
  }
  .m-0 {
    margin: 0px;
  }
  .m-l-5 {
    margin-left: 5px;
  }
  .m-l-10 {
    margin-left: 10px;
  }
  .m-l-15 {
    margin-left: 15px;
  }
  .m-l-20 {
    margin-left: 20px;
  }
  .m-l-30 {
    margin-left: 30px;
  }
  .m-l-40 {
    margin-left: 40px;
  }
  .m-r-5 {
    margin-right: 5px;
  }
  .m-r-10 {
    margin-right: 10px;
  }
  .m-r-15 {
    margin-right: 15px;
  }
  .m-r-20 {
    margin-right: 20px;
  }
  .m-r-30 {
    margin-right: 30px;
  }
  .m-r-40 {
    margin-right: 40px;
  }
  .m-t-0 {
    margin-top: 0px;
  }
  .m-t-2 {
    margin-top: 2px;
  }
  .m-t-5 {
    margin-top: 5px;
  }
  .m-t-10 {
    margin-top: 10px;
  }
  .m-t-15 {
    margin-top: 15px;
  }
  .m-t-20 {
    margin-top: 20px;
  }
  .m-t-30 {
    margin-top: 30px;
  }
  .m-t-40 {
    margin-top: 40px;
  }
  .m-b-0 {
    margin-bottom: 0px;
  }
  .m-b-5 {
    margin-bottom: 5px;
  }
  .m-b-10 {
    margin-bottom: 10px;
  }
  .m-b-15 {
    margin-bottom: 15px;
  }
  .m-b-20 {
    margin-bottom: 20px;
  }
  .m-b-30 {
    margin-bottom: 30px;
  }
  .m-b-40 {
    margin-bottom: 40px;
  }
  .vt {
    vertical-align: top;
  }
  .vm {
    vertical-align: middle;
  }
  .vb {
    vertical-align: bottom;
  }
  .b-r {
    border-right: 1px solid rgba(120, 130, 140, 0.13);
  }
  .b-l {
    border-left: 1px solid rgba(120, 130, 140, 0.13);
  }
  .b-b {
    border-bottom: 1px solid rgba(120, 130, 140, 0.13);
  }
  .b-t {
    border-top: 1px solid rgba(120, 130, 140, 0.13);
  }
  .b-all {
    border: 1px solid rgba(120, 130, 140, 0.13);
  }
  .b-white {
    margin-left: -14px;
    margin-right: -14px;
    border: 1px solid #fbfbfb;
  }
  .overflow-hidden {
    overflow: hidden;
  }
}

//Margin Top
.m-t-4 {
  margin-top: 4px;
}

.m-t-6 {
  margin-top: 6px;
}

.m-t-8 {
  margin-top: 8px;
}

.m-t-10 {
  margin-top: 10px;
}

.m-t-12 {
  margin-top: 12px;
}

.m-t-14 {
  margin-top: 14px;
}

.m-t-16 {
  margin-top: 16px;
}

.m-t-18 {
  margin-top: 18px;
}

.m-t-20 {
  margin-top: 20px;
}

//Margin Bottom
.m-b-4 {
  margin-bottom: 4px;
}

.m-b-6 {
  margin-bottom: 6px;
}

.m-b-8 {
  margin-bottom: 8px;
}

.m-b-10 {
  margin-bottom: 10px;
}

.m-b-12 {
  margin-bottom: 12px;
}

.m-b-14 {
  margin-bottom: 14px;
}
.m-b-15 {
  margin-bottom: 15px;
}
.m-b-16 {
  margin-bottom: 16px;
}

.m-b-18 {
  margin-bottom: 18px;
}

.m-b-20 {
  margin-bottom: 20px;
}

//Margin Left
.m-l-4 {
  margin-left: 4px;
}

.m-l-6 {
  margin-left: 6px;
}

.m-l-8 {
  margin-left: 8px;
}

.m-l-10 {
  margin-left: 10px;
}

.m-l-12 {
  margin-left: 12px;
}

.m-l-14 {
  margin-left: 14px;
}

.m-l-16 {
  margin-left: 16px;
}

.m-l-18 {
  margin-left: 18px;
}

.m-l-20 {
  margin-left: 20px;
}

//Margin Right
.m-r-4 {
  margin-right: 4px;
}

.m-r-6 {
  margin-right: 6px;
}

.m-r-8 {
  margin-right: 8px;
}

.m-r-10 {
  margin-right: 10px;
}

.m-r-12 {
  margin-right: 12px;
}

.m-r-14 {
  margin-right: 14px;
}

.m-r-16 {
  margin-right: 16px;
}

.m-r-18 {
  margin-right: 18px;
}

.m-r-20 {
  margin-right: 20px;
}

.flex-space {
  flex: 1;
}
.flex-width {
  @include flex-width;
}
.auto-margin {
  margin: auto;
}
.text-align-center {
  text-align: center;
}

.display-hidden {
  display: none;
}
