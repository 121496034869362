.date-wrapper {
  //   .CalendarDay__selected,
  //   .CalendarDay__selected:active,
  //   .CalendarDay__selected:hover {
  //     background: #2f53e6 !important;
  //     color: #fff;
  //     border-radius: 50%;
  //     border-top-left-radius: 50%;
  //     border-bottom-left-radius: 50%;
  //     background: #e3e9ff;
  //     // border: none;
  //   }
  //   .CalendarDay__selected_span {
  //     background: #e3e9ff;
  //     color: #212121;
  //     // border-radius: 50%;
  //   }
  .DayPickerNavigation__horizontal {
    display: flex;
    width: 100%;
    justify-content: space-around;
    top: 20px;
  }
  .CalendarMonth_caption {
    color: $c-text;
    font-size: 16px;
  }
  .CalendarDay__default {
    border: none;
  }
  .DayPickerNavigation_button__horizontalDefault {
    padding: 4px;
    border-radius: 6px !important;
    background: linear-gradient(320deg, #f2f3f6 0%, #e5e6ec 100%);
  }
  .DayPickerNavigation_button__default {
    border: none;
  }
  .CalendarDay__selected,
  .CalendarDay__selected:active,
  .CalendarDay__selected:hover {
    background: #2f5cff;
    border: none;
  }
  .CalendarDay__selected_span {
    background: #78aeff;
    border: none;
  }
}
