// !! late checkout page
.late-checkout-clock {
  margin-bottom: 8px;

  .hours {
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    font-size: 77px;
    font-weight: 600;
    height: 285px;
    color: #394d86;
    text-shadow: 2px 0.5px #b7b7b7;
  }
  .hours-tt {
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    font-size: 30px;
    font-weight: 600;
    height: 400px;
    color: #394d86;
    text-shadow: 2px 0.5px #b7b7b7;
  }
  .MuiPickersBasePicker-pickerView {
    display: flex;
    max-width: unset;
    min-width: unset;
    min-height: unset;
    overflow-x: hidden;
    flex-direction: column;
    justify-content: center;
  }
  .MuiPickersClock-container {
    margin: 0px;
    margin-bottom: 10px;
  }
  .MuiPickersStaticWrapper-staticWrapperRoot {
    background: transparent;
  }
  .MuiPickersClock-clock {
    background: linear-gradient(0deg, #e5e8f7 0%, #eaedf4 100%);
    box-shadow: 5px 5px 10px #cccccc, -5px -5px 10px #f9f9f9;
    margin-top: 10px;
  }

  .MuiPickersClock-squareMask {
    z-index: 1000;
  }
  .MuiPickersClockNumber-clockNumber {
    color: #394d86;
    text-shadow: 1px 0.5px #b7b7b7;
    font-weight: 600;
  }
  .MuiPickersClockNumber-clockNumberSelected {
    color: white;
    background: linear-gradient(0deg, #506293 0%, #3d4c76 100%);
    z-index: 1000;
  }
  .MuiPickersClockPointer-pointer {
    background: transparent;
  }
  .MuiPickersClock-pin {
    background: transparent;
  }
}

.total-section {
  position: fixed;
  bottom: 96px;
  justify-content: center;
  /* display: flex; */
  width: 100%;
}
