.extend-stay-section {
  display: flex;
  justify-content: center;
  margin-bottom: 14px;
  .date-circle {
    // background: antiquewhite;
    box-shadow: inset 4px 4px 6px #cccccc, inset -4px -4px 6px #ffffff;
    height: 165px;
    width: 165px;
    border-radius: 50%;
    // display: block;
    // align-items: center;
    // justify-content: center;
    .d-date {
      font-size: 65px;
      line-height: 70px;
      font-weight: 600;
      text-shadow: 2px 0.5px #b7b7b7;
      margin-top: 30px;
    }
    .m-y-date {
      font-size: 18px;
      line-height: 20px;
      font-weight: 600;
      text-shadow: 2px 0.5px #b7b7b7;
    }
  }
}

.extend-stay-date-picker {
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  .MuiPickersStaticWrapper-staticWrapperRoot {
    box-shadow: 5px 5px 10px #cccccc, -5px -5px 10px #f9f9f9;
    border-radius: 10px;
    background: linear-gradient(320deg, #e5e8f7 0%, #eaedf4 100%);
  }
  .MuiPickersCalendarHeader-switchHeader {
    margin-top: 0px;
    margin-left: 20px;
    margin-right: 20px;
    position: relative;
  }
  .MuiPickersCalendarHeader-iconButton {
    width: 24px;
    height: 24px;
    color: #506293;
    background: linear-gradient(320deg, #f2f3f6 0%, #e5e6ec 100%);
    border-radius: 8px;
    padding: 5px;
    box-shadow: 2px 2px 6px #cccccc, -2px -2px 6px #f9f9f9;
  }
  .MuiPickersCalendarHeader-iconButton:first-child {
    position: absolute;
    right: 40px;
  }
  .MuiTypography-alignCenter {
    text-align: unset;
    font-size: 15px;
    font-weight: 700;
  }
  .MuiPickersCalendarHeader-dayLabel {
    color: #506293;
    width: 40px;
  }
  .MuiPickersCalendar-week div {
    width: 45px;
    justify-content: center;
    display: flex;
  }
  .MuiPickersDay-day {
    width: 30px;
    height: 30px;
    // color: #506293;
    font-size: 11px;
    font-weight: 500;
  }
  .circle-date-selected .MuiPickersDay-day {
    width: 30px;
    height: 30px;
    background: transparent;
    box-shadow: inset 4px 4px 6px #cccccc, inset -4px -4px 6px #ffffff;
    color: #3d4c76;
    padding: 0px !important;
  }
  .MuiPickersDay-daySelected {
    color: #fff;
    background: linear-gradient(0deg, #2f57ff 0%, #2994ff 100%);

    font-weight: 500;
  }
  .MuiPickersCalendar-transitionContainer {
    min-height: 160px;
    margin-top: 6px;
  }
  .MuiPickersBasePicker-pickerView {
    min-height: 240px;
  }
}
