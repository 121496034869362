.card-wrapper.MuiCard-root {
  @include border-box-shadow;
}
.card-wrapper {
  margin-bottom: 15px;
  .MuiPaper-root {
    box-shadow: unset;
  }
  .MuiCardContent-root {
    color: $c-text;
    padding: 14px;
    .left {
      &.MuiFormControl-root,
      &.MuiAutocomplete-root {
        width: 50%;
        padding-right: 6px;
      }
    }
    .right {
      &.MuiFormControl-root,
      &.MuiAutocomplete-root {
        width: 50%;
        padding-left: 6px;
      }
    }
    &:last-child {
      padding-bottom: 14px;
    }
    .MuiFormControl-root {
      width: 100%;
      margin-bottom: 12px;
      box-sizing: border-box;
    }
  }
  //! avatar
  .avatar-section {
    display: flex;
    justify-content: center;
    .MuiAvatar-root {
      margin: auto;
      width: 100px;
      height: 100px;
      box-shadow: 5px 5px 10px #cccccc, -5px -5px 10px #f9f9f9;
    }
    .MuiAvatar-colorDefault {
      color: $c-white;
      background: $c-gradient-3;
    }
    .MuiAvatar-img {
      object-fit: contain;
      background: $c-gradient-3;
    }
    .float-img-upload {
      label {
        width: 45px;
        height: 45px;
        position: absolute;
        border-radius: 100px;
        opacity: 0;
        cursor: pointer;
      }
    }

    .MuiBadge-anchorOriginBottomRightRectangle {
      right: 0;
      bottom: 0;
      transform: scale(1) translate(25%, -40%);
      transform-origin: 100% 100%;
    }
    .MuiFab-primary {
      background: $c-gradient;
    }
  }

  .rm-padding-card {
    margin: -14px !important;
    position: relative;
    img {
      width: 100%;
      height: 80px;

      display: flex;
      object-fit: cover;
    }
    .logo-section {
      background-color: rgba(0, 0, 0, 0.4);
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
      z-index: 10;
      width: 100%;
      height: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 50px;
        height: 50px;
        display: flex;
      }
    }
  }
  .room-detail-section {
    .MuiIconButton-root {
      margin-left: 6px;
      padding: 0px 4px;
      border-radius: 6px !important;
      box-shadow: 2px 2px 4px #cccccc, -2px -2px 4px #f9f9f9 !important;
      @include border-box-shadow;
      img {
        width: 15px;
        height: 15px;
      }
    }
    .check-in-bed img {
      margin-top: -14px !important;
      margin-right: -14px;
    }
  }
  .guest-section {
    a {
      color: #818cac;
    }
    img {
      width: 14px;
      height: 14px;
      margin-right: 4px;
    }
  }
}
