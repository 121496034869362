.footer {
  width: 100%;
  position: fixed;
  bottom: 38px;
  z-index: 100;
  right: 0;
  background-color: transparent;
  @media screen and (max-width: 599px) {
    position: fixed;
    bottom: 32px;
    right: 0;
  }

  .footer-section {
    display: flex;
    justify-content: center;
    padding: 8px 24px;
    @media screen and (min-width: 600px) {
      //   justify-content: flex-end;
      .MuiButtonBase-root {
        margin-left: 18px;
      }
    }
    .footer-btn {
      @include main-button;
    }
    .MuiButtonBase-root {
      min-width: 360px;
      @media screen and (max-width: 600px) {
        min-width: 280px;
        width: 100%;
      }

      &.single {
        margin: 0 auto;
      }
      &.double {
        min-width: 180px !important;
        @media screen and (max-width: 600px) {
          min-width: 50%;
          width: 100%;
        }
      }
    }
    .MuiButton-label {
      text-transform: initial;
      //   text-shadow: 1px 1.2px #212121;
      font-size: 16px;
      font-weight: 700;
      // white-space: nowrap;
    }
    .btn-wrapper {
      padding: 8px 0px;
      width: 100%;
      text-align: center;
    }
  }
}
