@import '../../styles/mixins.scss';
@import '../../styles/typography.scss';
@import '../../styles/color.scss';

.common-icon.MuiIconButton-root {
  box-shadow: 5px 5px 10px #cccccc, -5px -5px 10px #f9f9f9;
  border-radius: 10px;
  margin: 6px 0px;
  width: 40px;
  height: 40px;
  padding: 0px;
  img {
    width: 25px;
    height: 25px;
  }
}
.common-secondary-icon.MuiIconButton-root {
  background: #ef4b4b !important;
  box-shadow: 5px 5px 10px #cccccc, -5px -5px 10px #f9f9f9;
  border-radius: 10px;
  margin: 6px 0px;
  width: 40px;
  height: 40px;
  padding: 0px;
  img {
    width: 25px;
    height: 25px;
  }
}

.otp-button.MuiButton-root {
  font-family: Poppins, sans-serif;
  @extend .fw-semibold;
  border-radius: 6px;
  text-transform: capitalize;
  @extend .box-shadow-1;

  &.MuiButton-containedPrimary {
    background: linear-gradient(90deg, #586488, #8391b7);
    color: white;
  }
  &.MuiButton-contained.Mui-disabled {
    color: rgba(0, 0, 0, 0.26);
    box-shadow: none;
    background: rgba(0, 0, 0, 0.12) !important;
  }
}

.register-button.MuiButton-root {
  font-family: Poppins, sans-serif;
  @extend .fw-semibold;
  height: 45px;
  border-radius: 10px;
  text-transform: capitalize;
  @extend .box-shadow-1;

  &.MuiButton-containedPrimary {
    background: linear-gradient(0deg, #2f5cff 0%, #2994ff 100%);
    color: white;
  }
  &.MuiButton-contained.Mui-disabled {
    color: rgba(0, 0, 0, 0.26);
    box-shadow: none;
    background: rgba(0, 0, 0, 0.12) !important;
  }
}
