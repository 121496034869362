.hotel-detail-wrapper {
  .rating {
    border-radius: 5px;
    box-shadow: 5px 5px 10px #cccccc, -5px -5px 10px #f9f9f9;
    padding: 2px 4px;
    width: 40px;
    img {
      width: 15px;
      height: 15px;
      padding-right: 4px;
    }
  }
  .share-info {
    .MuiIconButton-root {
      box-shadow: 5px 5px 10px #cccccc, -5px -5px 10px #f9f9f9;
      border-radius: 10px;
      margin: 0px 8px;
      img {
        width: 25px;
        height: 25px;
      }
    }
  }
}
.amenities-wrapper {
  text-align: center;
  .MuiIconButton-root {
    box-shadow: 5px 5px 10px #cccccc, -5px -5px 10px #f9f9f9;
    border-radius: 10px;
    margin: 6px 0px;
    width: 40px;
    height: 40px;
    img {
      width: 25px;
      height: 25px;
    }
  }
  .label {
    width: 50px;
    margin: auto;
  }
}

.about {
  a.show-more {
    color: #225cfa;
    width: 80px;
    height: 18px;
    padding: 2px;
    text-align: center;
    display: block;
    border-radius: 4px;
    margin: 6px 0px;
    font-weight: 500;
    text-decoration: none;
    border-radius: 5px;
    box-shadow: 5px 5px 10px #cccccc, -5px -5px 10px #f9f9f9;
  }
}

.stay-duration {
  justify-content: center;
  display: flex;
  border-bottom: 2px dotted #637691;
  width: 65px;
}
.promo-section {
  .MuiOutlinedInput-input {
    padding: 6px;
    font-size: 12px;
    border-radius: 5px;
    box-shadow: inset 4px 4px 6px #cccccc, inset -4px -4px 6px #ffffff;
  }
  .MuiTextField-root .MuiOutlinedInput-root fieldset {
    border-color: transparent;
  }
  .apply-btn-section {
    margin: auto;
    text-align: end;
  }
  .apply-btn {
    &.MuiButton-root {
      font-family: Poppins, sans-serif;
      font-weight: 500;
      border-radius: 4px;
      text-transform: capitalize;
      box-shadow: 5px 5px 10px #cccccc, -5px -5px 10px #f9f9f9;

      &.MuiButton-containedPrimary {
        background: linear-gradient(330deg, #2f5cff 0%, #2994ff 100%);
      }
    }
    &.MuiButton-contained.Mui-disabled {
      color: rgba(0, 0, 0, 0.26);
      box-shadow: none;
      background: rgba(0, 0, 0, 0.12) !important;
    }
  }
}

.room-type-section {
  .MuiAvatar-root {
    width: 64px;
    height: 70px;
    img {
      object-fit: cover;
    }
  }
  .special-request-section {
    display: flex;
    justify-content: space-between;
  }

  .MuiOutlinedInput-input {
    padding: 6px;
    font-size: 12px;
    border-radius: 5px;
    box-shadow: inset 4px 4px 6px #cccccc, inset -4px -4px 6px #ffffff;
  }
  .MuiTextField-root .MuiOutlinedInput-root fieldset {
    border-color: transparent;
  }
  .MuiOutlinedInput-adornedEnd {
    padding-right: 6px;
  }
  textarea {
    width: 180px;
    font-family: Poppins, sans-serif !important;
    padding: 6px;
    font-size: 12px;
    border-radius: 5px;
    box-shadow: inset 4px 4px 6px #cccccc, inset -4px -4px 6px #ffffff;
    border-color: transparent;
    background: transparent;
  }
  textarea::placeholder {
    color: #acb5d0;
  }
  .MuiIconButton-root {
    padding: 0px;
    width: 30px;
    height: 30px;
    img {
      width: 20px;
      height: 20px;
    }
  }
}

.guest-details {
  display: flex;
  justify-content: flex-end;
  .edit-icon {
    margin-right: 14px;
  }
  .MuiIconButton-root {
    box-shadow: 5px 5px 10px #cccccc, -5px -5px 10px #f9f9f9;
    border-radius: 10px;
    // margin: 0px 8px;
    width: 30px;
    height: 30px;
    img {
      width: 20px;
      height: 20px;
    }
    &.MuiIconButton-root.Mui-disabled {
      background: rgba(0, 0, 0, 0.12) !important;
    }
  }

  .checkin-btn {
    &.MuiButton-root {
      font-family: Poppins, sans-serif;
      font-weight: 500;
      border-radius: 4px;
      text-transform: capitalize;
      box-shadow: 5px 5px 10px #cccccc, -5px -5px 10px #f9f9f9;

      &.MuiButton-containedPrimary {
        background: linear-gradient(0deg, #2f5cff 0%, #2994ff 100%);
        color: white;
        padding: 4px 10px;
      }
    }
    &.MuiButton-contained.Mui-disabled {
      color: rgba(0, 0, 0, 0.26);
      box-shadow: none;
      background: rgba(0, 0, 0, 0.12) !important;
    }
  }
}
