/* 
Neumorphism Design Color palette Variables

bg - background
c  - Color
*/
$c-bg-auth: #dfe3eb;
$c-grey-1: #e9ecf4;

//bg
$c-grey: #d1d9e6;
$c-medium-blue: #2c77ff;
// blue
$c-light-blue-1: #eaedf4;
//* status
$c-green: #00bf0f;
$c-red: #ff4568;
$c-orange: #ffa700;
$c-text-01: #dfe6eb;
//* Text

$c-white: #fff;
$c-text: #394d86;
$c-text-1: #637691;
// $c-text-2: #9599a6;

$c-bg: #eaedf4;
$c-tiffanyblue: #00d3e5;
$c-lightblue: #2994ff;
$c-blue: #2f5cff;
$c-blue-1: #225cfa;
$c-blue-2: #5367ff;
$c-greyblue-1: #eaedf4;
$c-greyblue-2: #e5e8f7;

$c-gradient: linear-gradient(0deg, $c-blue 0%, $c-lightblue 100%);
$c-gradient-1: linear-gradient(0deg, $c-greyblue-2 0%, $c-greyblue-1 100%);
$c-gradient-2: linear-gradient(320deg, $c-greyblue-1 0%, $c-greyblue-2 100%);
$c-gradient-3: linear-gradient(0deg, #506293 0%, #3d4c76 100%);
$c-gradient-4: linear-gradient(320deg, #ffc31f 0%, #ffa700 100%);

//
.color-black {
  color: #212121 !important;
}
.color-text {
  color: $c-text;
}

.color-blue-gradient {
  background: $c-gradient;
}
.color-tiffanyblue {
  color: $c-tiffanyblue;
}
.color-blue-1 {
  color: $c-blue-1;
}
.color-blue {
  color: $c-blue;
}
.color-blue-2 {
  color: $c-blue-2;
}

.color-text-2 {
  color: #818cac;
}
.color-text-1 {
  color: $c-text-1;
}
.color-red {
  color: #ff6a6a;
}
.color-orange {
  color: $c-orange;
}
