@import './color.scss';
@import './typography.scss';
// Full width + display flex

@mixin flex-width {
  width: 100%;
  display: flex;
}
// main button with gradient
@mixin main-button {
  &.MuiButton-root {
    font-family: Poppins, sans-serif;
    @extend .fw-semibold;
    height: 45px;
    border-radius: 10px;
    text-transform: capitalize;
    @extend .box-shadow-1;

    &.MuiButton-containedPrimary {
      background: $c-gradient;
      color: white;
    }
  }
  &.MuiButton-contained.Mui-disabled {
    color: rgba(0, 0, 0, 0.26);
    box-shadow: none;
    background: rgba(0, 0, 0, 0.12) !important;
  }
}

@mixin standard-boxshadow {
  @extend .box-shadow-1;
}

@mixin border-box-shadow {
  border-radius: 10px;
  background: $c-gradient-1;
  box-shadow: 5px 5px 10px #cccccc, -5px -5px 10px #f9f9f9;
}

@mixin plus-minus-button {
  .MuiIconButton-root {
    width: 20px;
    height: 20px;
    &.MuiIconButton-root.Mui-disabled {
      color: $c-white;
      background: linear-gradient(0deg, #bdcbeb 0%, #e9eaf4 100%);
    }
    .MuiSvgIcon-root {
      width: 18px;
      height: 18px;
    }
  }
}
