@import '../../styles/color.scss';
@import '../../styles/shadow.scss';
.main-header {
  flex-grow: 1;
  .MuiAppBar-colorPrimary {
    background: $c-greyblue-2;
    box-shadow: none;
  }
  .MuiToolbar-root {
    box-sizing: border-box;
    min-height: 68px;
    @media screen and (min-width: 600px) {
      max-width: 1200px;
      // min-width: 800px;
      margin: 0 auto;
      min-height: 68px;
      width: 100%;
      padding: 0px 24px;
    }
  }

  .MuiToolbar-gutters {
    padding-left: 18px;
    padding-right: 18px;
  }
  .text {
    margin: auto 0;
    color: $c-text;
    text-shadow: 2px 2px #cecece;
  }
  // span {
  //   margin: auto 0;
  //   color: $c-text;
  // }
  &.rm-header {
    @media screen and (max-width: 600px) {
      .MuiAppBar-colorPrimary {
        background: transparent;
        box-shadow: none;
      }
      .title {
        display: none;
      }
      .left-button {
        .MuiIconButton-label {
          box-shadow: none !important;
        }
      }
    }
  }
  .left-button {
    padding: 0px;
    padding-right: 12px;
    color: #fff;
    border-radius: 0;
    .MuiIconButton-label {
      @include standard-boxshadow;
      // box-shadow: 6px 6px 12px #3b59d6, -6px -6px 12px #469af9 !important;
      background: $c-gradient-1;
      border-radius: 9px;
      padding: 4px;
    }
  }
  .right-button {
    padding: 0px;
    color: #fff;
    border-radius: 0;
    .MuiIconButton-label {
      @include standard-boxshadow;
      // box-shadow: 6px 6px 12px #3b59d6, -6px -6px 12px #469af9 !important;
      background: $c-gradient-1;

      border-radius: 9px;
      padding: 4px;
    }
    &.more-icon .MuiIconButton-label {
      padding: 2px;
    }
    .MuiBadge-anchorOriginTopRightRectangle {
      box-shadow: 4px 4px 10px #636363, -7px -7px 13px #ffffff;
      top: 0;
      right: 0;
      transform: scale(1) translate(-9%, -17%);
      transform-origin: 100% 0%;
    }
    .MuiBadge-colorPrimary {
      background: $c-orange;
      color: #212121;
    }
    .MuiBadge-badge {
      width: 15px;
      height: 15px;
      font-size: 10px;
      min-width: 14px;
    }
  }
}
