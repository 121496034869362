// @import "@ifca-ui/neumorphism/src/styles/root.scss";
@import './styles/hotel.scss';
@import './styles/latecheckout.scss';
@import './styles/extendstay.scss';
@import './styles/bills.scss';
@import '../components//InstallBanner/installbanner.scss';
@import '~react-phone-input-2/lib/material.css';
.about-us-wrapper {
  .MuiAvatar-root {
    width: 80%;
    height: 80%;
    max-width: 520px;
    margin: auto;
    margin-bottom: 12px;
  }
  text-align: center;
}
.contact-input {
  .react-tel-input .special-label {
    color: #394d86 !important;
    background: transparent;
    margin-left: -26px;
  }
  .react-tel-input .form-control {
    width: 100%;

    background: transparent;
    border-color: transparent;
    color: #394d86 !important;
    border-bottom: 1px solid #0000006b !important;
    padding: 12px 18px 12px 50px;
  }
  .react-tel-input .country-list {
    max-height: 110px !important;
  }
  .react-tel-input .special-label {
    font-size: 12px;
  }
}

.video-section {
  video {
    top: 0px;
    left: 0px;
    display: block;
    position: absolute;
    overflow: hidden;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .scan-border {
    @media screen and (min-width: 600px) {
      svg {
        width: 500px;
        height: 350px;
      }
    }
  }
}
.contact-input-new.react-tel-input {
  margin-bottom: 6px;
  margin-top: 16px;

  .special-label {
    color: #394d86 !important;
    background: transparent;
    margin-left: -26px;
  }

  .form-control {
    width: 100%;
    background: transparent;
    border-color: transparent;
    color: #394d86 !important;
    border-bottom: 1px solid #0000006b !important;
    padding: 12px 0px 12px 50px;
    border-radius: unset;
  }

  .country-list {
    max-height: 110px !important;
  }

  .special-label {
    font-size: 12px;
  }

  .invalid-number-message {
    left: -4px;
    top: 47px;
    color: #d32f2f;
    font: 400 0.75rem/1.66 Poppins, sans-serif;
  }

  .form-control:focus {
    box-shadow: unset;
  }
}

.otp-input {
  div {
    margin: auto;
  }
  input {
    width: 52px !important;
    height: 62px;
    border-radius: 10px;
    border: 1px solid transparent;
    box-shadow: rgb(0 0 0 / 20%) 0px 2px 4px -1px,
      rgb(0 0 0 / 14%) 0px 4px 5px 0px, rgb(0 0 0 / 12%) 0px 1px 10px 0;
    font-size: 30px;
  }
  input::placeholder {
    color: #c2c2c2;
  }
}
