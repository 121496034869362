.image-preview {
  display: block;
  margin-bottom: 8px;
  .MuiBadge-badge {
    width: 20px;
    box-shadow: 5px 5px 10px #cccccc, -5px -5px 10px #f9f9f9;
  }
  img {
    width: 100px;
    height: 100px;
    background: white;
    border-radius: 6px;
    box-shadow: 5px 5px 10px #cccccc, -5px -5px 10px #f9f9f9;
  }
}

.remove-badge {
  .MuiBadge-badge {
    width: 20px;
    box-shadow: 5px 5px 10px #cccccc, -5px -5px 10px #f9f9f9;
  }
}

audio {
  height: 55px;
  -webkit-transition: all 0.5s linear;
  -moz-transition: all 0.5s linear;
  -o-transition: all 0.5s linear;
  transition: all 0.5s linear;
  margin-top: 5px;
  margin-bottom: 5px;
}
audio::-webkit-media-controls-panel {
  background: #dce3f4;
}

.common-button {
  @include main-button;
  height: 38px !important;
  width: 140px;
}
.common-button.MuiButton-outlinedPrimary {
  color: #2c6eff !important;
  border: 1px solid #2c6eff !important;
}

.rating {
  .MuiRating-root {
    font-size: 2rem;
  }
  .MuiRating-label {
    padding: 0px 4px;
  }
}
.rating-comment {
  margin-bottom: 20px;
  textarea {
    width: 290px;
    height: 148px !important;
    font-family: Poppins, sans-serif !important;
    padding: 6px;
    font-size: 12px;
    border-radius: 5px;
    box-shadow: inset 4px 4px 6px #cccccc, inset -4px -4px 6px #ffffff;
    border-color: transparent;
    background: transparent;
  }

  textarea::placeholder {
    color: #acb5d0;
  }
}

.border-box-shadow {
  @include border-box-shadow;
}

.html-template img {
  width: 100%;
}

.voice-wave-wrapper {
  border: 1px solid #cccccc;
  border-radius: 6px;
  height: 100%;
  canvas {
    width: 100%;
    height: 40px;
  }
}
