@import '../../styles/mixins.scss';

.search-filter-wrapper {
  margin-bottom: 30px;
  color: $c-text-1;
  svg {
    width: 0.8em;
    height: 0.8em;
    display: inline-block;
    font-size: 1.5rem;
  }
  .MuiFilledInput-root {
    margin-bottom: 14px;
    // border-radius: 10px;
    // background: $c-gradient-1;
    // box-shadow: 7px 7px 14px #a7a9b4, -7px -7px 14px #ffffff;
    @include border-box-shadow;

    .MuiFilledInput-input {
      padding: 12px 14px;
      color: $c-text-1;
    }
  }
  .MuiFilledInput-adornedEnd {
    padding-right: 14px !important;
  }

  .date-section {
    .date-picker {
      cursor: pointer;
      // border-radius: 10px;
      // background: $c-gradient-1;
      // box-shadow: 7px 7px 14px #a7a9b4, -7px -7px 14px #ffffff;
      @include border-box-shadow;

      padding: 12px 14px;
      display: flex;
      .date-value {
        margin: auto;
      }
    }
    .MuiSvgIcon-root {
      margin: auto;
    }
  }
  .filter-section {
    .adult-selection {
      cursor: pointer;
      // border-radius: 10px;
      // background: $c-gradient-1;
      // box-shadow: 7px 7px 14px #a7a9b4, -7px -7px 14px #ffffff;
      @include border-box-shadow;

      padding: 12px 14px;
      display: flex;
      .value {
        margin: auto;
      }
    }
    .search-btn {
      @include main-button;
      font-size: 16px;
      box-shadow: 5px 5px 10px #cccccc, -5px -5px 10px #f9f9f9 !important;
    }
  }
}
