.drawer-wrapper {
  .MuiDrawer-paper {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    background: $c-gradient-1;
    // height: 100px;
    padding: 14px;
    align-items: center;
  }
  .color-divider {
    background: linear-gradient(0deg, #b8bed9 0%, #cdd7ef 100%);
    height: 8px;
    border: 10px;
    width: 16%;
    border-radius: 10px;
    margin-bottom: 12px;
  }

  .request-section {
    cursor: pointer;
    padding: 20px;
    border-radius: 10px;
    background: $c-gradient-1;
    box-shadow: 7px 7px 14px #a7a9b4, -7px -7px 14px #ffffff;
  }
}
