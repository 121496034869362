.common-dialog .MuiDialog-paper {
  @include border-box-shadow;
  box-shadow: none;
}
.common-dialog {
  .MuiDialogContent-root {
    padding: 15 20px;
    color: #394d86;
  }
  .MuiDialogContent-root:first-child {
    padding-top: 14px;
  }
  .MuiDialogActions-root {
    padding: 0px 20px 20px 20px;
  }
  .action-btn.MuiButton-root {
    font-family: Poppins, sans-serif;
    font-weight: 500;
    border-radius: 4px;
    text-transform: capitalize;
    box-shadow: 5px 5px 10px #cccccc, -5px -5px 10px #f9f9f9;
  }
  .action-btn.MuiButton-containedPrimary {
    background: linear-gradient(0deg, #2f5cff 0%, #2994ff 100%) !important;
    padding: 4px 10px;
    color: white;
  }
  .action-btn.MuiButton-contained.Mui-disabled {
    color: rgba(0, 0, 0, 0.26);
    box-shadow: none;
    background: rgba(0, 0, 0, 0.12) !important;
  }

  .color-divider {
    background: linear-gradient(0deg, #b8bed9 0%, #cdd7ef 100%);
    height: 8px;
    margin: auto;
    border: 10px;
    width: 18%;
    border-radius: 10px;
    margin-bottom: 12px;
  }
  .dialog-content {
    margin: auto;
    .hotel-logo {
      margin-bottom: 12px;
      // padding: 12px 0px;
      // padding-bottom: 12px;
      display: flex;
      margin: auto 0;
      width: 100%;
      /* align-items: center; */
      justify-content: center;
      .MuiAvatar-root {
        width: 130px;
        height: 130px;
        background: $c-gradient-2;
        box-shadow: 4px 4px 10px #a7a9b4, -4px -4px 10px #ffffff;

        .MuiAvatar-img {
          object-fit: contain;
          width: 80px;
          height: 80px;
        }
      }

      .done-icon.MuiAvatar-root {
        // width: 130px;
        // height: 130px;
        background: none !important;
        box-shadow: none !important;
        .MuiAvatar-img {
          object-fit: contain;
          width: 100px;
          height: 100px;
        }
      }
    }
    .hotel-info {
      .label {
        line-height: 12px;
        white-space: break-spaces;
      }
      img {
        padding-right: 6px;
      }
    }
    .help-info {
      .MuiAvatar-root {
        // background: $c-gradient-2;
        // box-shadow: 4px 4px 10px #a7a9b4, -4px -4px 10px #ffffff;
        .MuiAvatar-img {
          cursor: pointer;

          object-fit: contain;
          height: 60px;
          width: 60px;
        }
      }
      .align-center {
        justify-content: center;
        display: flex;
      }
    }
  }
  .single-button {
    display: flex;
    align-items: center;
    justify-content: center !important;
    width: 100%;
  }
  .double-button {
    display: flex;
    align-items: center;
    justify-content: space-between !important;
    width: 100%;
    .MuiButton-root:first-child {
      margin: auto 10px auto auto;
      width: 80px;
    }
    .MuiButton-root:last-child {
      margin: auto auto auto 10px;
      width: 80px;
    }
  }

  .request-section {
    cursor: pointer;
    padding: 20px;
    border-radius: 10px;
    background: $c-gradient-1;
    box-shadow: 7px 7px 14px #a7a9b4, -7px -7px 14px #ffffff;
  }

  .showmore-btn.MuiButton-root {
    font-family: Poppins, sans-serif;
    font-weight: 500;
    border-radius: 4px;
    text-transform: capitalize;
    box-shadow: 5px 5px 10px #cccccc, -5px -5px 10px #f9f9f9;
  }
  .showmore-btn.MuiButton-containedPrimary {
    background: $c-gradient-2 !important;
    padding: 4px 10px;
    color: $c-blue;
  }
  .showmore-btn.MuiButton-contained.Mui-disabled {
    color: rgba(0, 0, 0, 0.26);
    box-shadow: none;
    background: rgba(0, 0, 0, 0.12) !important;
  }
}
