// .quick-access-wrapper {
//   margin-bottom: 25px;
//   .card {
//     cursor: pointer;
//     padding: 8px;
//     text-align: center;
//     @include border-box-shadow;

//     // border-radius: 10px;
//     // background: $c-gradient-1;
//     // box-shadow: 7px 7px 14px #a7a9b4, -7px -7px 14px #ffffff;
//     svg {
//       width: 40px;
//       height: 40px;
//     }
//   }
//   .label {
//     text-align: center;
//     padding: 10px 0px;
//   }
//   .MuiBadge-root {
//     float: right;
//   }
//   .MuiBadge-colorPrimary {
//     background: $c-gradient-4;
//   }
// }

.room-info-wrapper {
  margin-bottom: 15px;

  //progress

  // Promo sections
  .promo-swiper.swiper-container {
    @include border-box-shadow;
    margin-bottom: 15px;
  }
  .promo-swiper {
    .swiper-slide {
      text-align: center;
      width: 100% !important;
      height: 90px;
      cursor: pointer;
      .swiper-slide {
        background-position: center;
        background-size: cover;
      }
      img {
        width: 100%;
        height: 90px;
        object-fit: cover;
      }
    }
    .swiper-pagination {
      bottom: -5px !important;
    }
    .swiper-pagination-bullet active {
      background: #2994ff;
    }
  }
}
.quick-access-section {
  .card {
    cursor: pointer;
    svg {
      width: 40px;
      height: 40px;
    }
  }
  .label {
    text-align: center;
  }
  .MuiBadge-root {
    float: right;
  }
  .MuiBadge-anchorOriginTopRightRectangle {
    transform: scale(1) translate(-50%, -50%);
  }
  .MuiBadge-colorPrimary {
    background: $c-gradient-4;
  }
}
.my-room-info {
  .room-content {
    margin: auto;
    .shadow {
      text-shadow: 1px -0.5px #b8bac5;
    }
  }
  .duration-icon {
    display: flex;
    margin: auto;
    margin-right: 4px;
    img {
      width: 14px;
      height: 14px;
      padding-right: 4px;
    }
  }
  .time-label {
    margin: auto;

    font-size: 10px;
    text-align: center;
    font-weight: 500;
    padding: 0px 6px;
    border-radius: 6px;

    color: #4961c4;
    background: linear-gradient(320deg, #bdcbeb 0%, #bdcbeb 100%);
  }
  .progress {
    width: 100%;
    height: 6px;

    border-radius: 10px;
    background: #c2c6d1;
    position: relative;
  }
  .progress-bar {
    height: 6px;
    border-radius: 10px;
    background: linear-gradient(
      90deg,
      rgba(41, 148, 255, 1) 0%,
      rgba(47, 87, 255, 1) 100%
    );
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
  }
  .MuiListItemAvatar-root {
    min-width: 66px;
  }
  .MuiAvatar-root.svg-icon {
    box-shadow: inset 4px 4px 6px #a7a9b4, inset -4px -4px 6px #ffffff;
    .MuiAvatar-img {
      object-fit: contain;
      background: transparent;
      width: 45px;
      height: 45px;
    }
  }
  .MuiAvatar-colorDefault.svg-icon {
    color: $c-white;
    background: transparent;
  }
  .MuiAvatar-root {
    width: 60px;
    height: 60px;
  }
}
