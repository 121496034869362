.core-list {
  //Typography
  .title {
    line-height: 22px;
  }

  .smTitle {
    line-height: 20px;
  }

  .xsTitle {
    line-height: 18px;
  }

  .xxTitle {
    line-height: 16px;
  }

  .desc {
    line-height: 16px;
    // color: #454545;
  }

  .mdDesc {
    line-height: 18px;
  }
  .rm-padding {
    margin: -8px -12px 0px !important;
  }
  &.MuiList-padding {
    padding-top: 0px;
  }
  .MuiListItemIcon-root {
    min-width: unset;
  }

  .MuiCheckbox-root {
    padding: 0px;
    margin: -2px 12px -2px -2px;
  }

  .extra-row {
    display: flex;
    overflow: hidden;
    align-items: center;
    width: 100%;
  }
  .MuiListItem-root.status {
    background: #eaeaea;
  }
  .MuiListItem-root {
    @include standard-boxshadow;
    border-radius: 10px;
    background: $c-gradient-2;
    margin-bottom: 14px;
    cursor: pointer;
    padding: 12px 14px;
    flex-wrap: wrap;
    //! Avatar company icon
    .MuiAvatar-colorDefault {
      color: $c-white;
      background: $c-gradient-3;
    }
    .MuiAvatar-img {
      object-fit: contain;
      background: $c-gradient-3;
    }
    .MuiListItemAvatar-root {
      min-width: 48px;
    }
    // SVG ICON
    .MuiAvatar-root.svg-icon {
      box-shadow: inset 4px 4px 6px #cccccc, inset -4px -4px 6px #ffffff;
      .MuiAvatar-img {
        object-fit: contain;
        background: transparent;
        width: 25px;
        height: 25px;
      }
    }
    .MuiAvatar-colorDefault.svg-icon {
      color: $c-white;
      background: transparent;
    }
    .MuiAvatar-root {
      box-shadow: 4px 4px 10px #cccccc, -4px -4px 10px #ffffff;
    }

    .MuiListItemText-multiline {
      margin: 0px;
      flex: 1;
    }
    .MuiListItemText-primary,
    .MuiListItemText-secondary {
      display: flex;
      align-items: center;
      color: unset;
      flex-wrap: wrap;
    }
    .indicator {
      font-size: 16px;
      margin-right: 2px;
    }
    .flex-space {
      flex: 1;
    }
  }

  .hotel.MuiListItem-root {
    // box-shadow: 6px 6px 10px #0a0b0c, -6px -6px 10px #ffffff;
    border-radius: 10px;
    // background: $c-gradient-2;
    height: 80px;
    margin-bottom: 14px;
    cursor: pointer;
    padding: 12px 14px;
    flex-wrap: wrap;
    .icon {
      img {
        padding-right: 4px;
      }
    }
    .text {
      @media screen and (max-width: 599px) {
        width: 250px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .rating {
      border-radius: 5px;
      // box-shadow: 2px 2px 4px #a7a9b4, -2px -2px 4px #ffffff;
      padding: 2px 4px;
      width: 40px;
      @include standard-boxshadow;
      line-height: 10px;

      img {
        width: 15px;
        height: 15px;
        padding-right: 4px;
      }
    }
    .MuiListItemAvatar-root {
      min-width: 110px;
      width: 80px;
      height: 80px;
      margin: -12px -14px !important;
      .MuiAvatar-root {
        width: 80px;
        height: 80px;
        border-radius: 10px;
        box-shadow: none;
      }
      .MuiAvatar-img {
        object-fit: cover;
        background: transparent;
      }
    }
  }
  .hotel.MuiIconButton-edgeEnd {
    width: 25px;
    height: 25px;
    cursor: pointer;
    &.MuiIconButton-root.Mui-disabled {
      color: $c-white;
      background: linear-gradient(0deg, #bdcbeb 0%, #e9eaf4 100%);
    }
    .MuiSvgIcon-root {
      width: 25px;
      height: 25px;
    }
    background: $c-gradient !important;

    color: $c-white;
  }
  .hotel.MuiBadge-root {
    display: block;
    .MuiBadge-colorSecondary {
      background: linear-gradient(320deg, #ffc31f 0%, #ffa700 100%);
    }
  }
  // Right  button section
  .MuiListItem-secondaryAction {
    padding-right: 50px !important;
  }
  .MuiIconButton-edgeEnd {
    padding: 0px;
    margin-right: 0px;
    // color: #212121;
  }
}
